import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn} from "@elements/dom-utils";

export function init () {
    onFind('.js-brand-slider__swiper', function(brandSlider){

        let numberOfSlides = findAllIn('.js-brand-slider__slide', brandSlider).length;

        import('swiper').then(module => {
            const Swiper = module.default;
            let swiper = new Swiper(brandSlider, {
                modules: [ module.Autoplay ],
                slidesPerView: 3.2,
                spaceBetween: 4,
                loop: numberOfSlides<7?false:true,
                autoplay: {
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                speed: 3000,
                freeMode: {
                    enabled: true,
                    sticky: false,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 6.8,
                        spaceBetween: 22,
                    }
                }
            });
        }).catch(error => {
            console.log(error);
        });
    });
}
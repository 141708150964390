import * as googleMap from "@elements/google-map";
import {onFind} from "@elements/init-modules-in-scope";
import axios from "axios";

export function init () {
    onFind('.js-google-map', function (map) {
        let url = map.getAttribute('data-map-url'),
            zoom = map.getAttribute('data-map-zoom') | 15;

        let clusteringOptions = {
            default: {
                styles: [{
                    height: 33,
                    url: "/static/img/map/poi-cluster.svg",
                    width: 33,
                    textColor: "#ffffff",
                    anchor: [0, 0]
                }]
            },
            active: {
                styles: [{
                    height: 33,
                    url: "/static/img/map/poi-cluster.svg",
                    width: 33,
                    textColor: "#ffffff",
                    anchor: [0, 0]
                }]
            }
        };


        axios({
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
            url: url,
            responseType: 'json'
        }).then(function (response) {
                googleMap.init({
                    mapOptions: {
                        zoom: zoom
                    },
                    pois: response.data.pois,
                    clusteringOptions,
                    infoBoxOptions: () => createInfoBoxStyle(),
                    poiStyles: () => createPoiStyles(),
                    onActivateMarker: (marker, api) => onActivateMarker(marker, api),
                    onDeactivateMarker: (marker, api) => onDeactivateMarker(marker, api)
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });

        googleMap.getApi(map).then((mapApi) => {
            let mapInstance = mapApi.getMapInstance();
            let location = map.getAttribute('data-map-bounds');

            if (location) {
                let data = JSON.parse(location),
                    bounds = new google.maps.LatLngBounds(new google.maps.LatLng(data.sw.lat, data.sw.lng), new google.maps.LatLng(data.ne.lat, data.ne.lng));

                mapInstance.fitBounds(bounds);
            }
        });
    });
}

function createPoiStyles(){
    return {
        default: {
            default: {
                url: '/static/img/map/marker.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        showroom: {
            default: {
                url: '/static/img/map/marker-showroom.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-showroom.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        production: {
            default: {
                url: '/static/img/map/marker-production.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-production.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        trader: {
            default: {
                url: '/static/img/map/marker-trader.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-trader.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        partner: {
            default: {
                url: '/static/img/map/marker-partners.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-partners.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        distributor: {
            default: {
                url: '/static/img/map/marker-distributors.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-distributors.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        academy: {
            default: {
                url: '/static/img/map/marker-academy.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-academy.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        'representative-office': {
            default: {
                url: '/static/img/map/marker-representative-office.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-representative-office.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        'secondary-subsidiary': {
            default: {
                url: '/static/img/map/marker.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        },
        'other': {
            default: {
                url: '/static/img/map/marker-other.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            },
            active: {
                url: '/static/img/map/marker-other.svg',
                size: new google.maps.Size(32, 35),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 35)
            }
        }
    };
}

function createInfoBoxStyle(){
    return {
        pixelOffset: new google.maps.Size(-130, -40),
        boxStyle: {
            width: "260px",
            background: "#fff",
            padding: "0",
            borderRadius: "6px"
        }
    };
}

function onActivateMarker(marker, mapApi) {
    if(marker.detailInfoBoxUrl) {

        if (marker.savedInfoBox) {
            mapApi.showInfoBoxByMarker(marker, marker.savedInfoBox);
        } else {
            let infoBoxPromise = mapApi.showInfoBoxByMarker(marker, `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`)

            let contentPromise = axios({
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: marker.detailInfoBoxUrl,
                responseType: 'json'
            });

            Promise.all([infoBoxPromise, contentPromise]).then(([infoBox, response]) => {
                infoBox.setContent(response.data.html);

                marker.savedInfoBox = response.data.html;
            });
        }
    }
}

function onDeactivateMarker(marker, mapApi) {
    mapApi.closeInfoBoxByMarker(marker);
}
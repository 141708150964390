"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn} from "@elements/dom-utils";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    onFind('.js-dropdown-submenu', function($list){
        let $parent = closest('.js-dropdown-submenu__parent', $list);
        let $toggle = findIn('.js-dropdown-submenu__toggle', $parent);
        let openEvt = matchMedia('(max-width: 767px)').matches ? 'click' : 'mouseover';
        on(openEvt, function(e){
            if(isMobile){
                e.preventDefault();
            }

            if(hasClass('is-active', $toggle)){
                removeClass('is-active', $toggle);
                removeClass('is-open', $list);
            }
            else{
                onFind('.js-dropdown-submenu__toggle', function($buttons){
                    removeClass('is-active', $buttons);
                });
                onFind('.js-dropdown-submenu', function($lists){
                    removeClass('is-open', $lists);
                });

                window.addEventListener(openEvt, function _listener(evt) {
                    if (!$parent.contains(evt.target)) {
                        removeClass('is-open', $list);
                        removeClass('is-active', $toggle);
                        window.removeEventListener("click", _listener, true);
                    }
                }, true);
                
                addClass('is-open', $list);
                addClass('is-active', $toggle);
            }
        }, $toggle);

        if(isMobile()){
            let $back = findIn('.js-dropdown-submenu__back', $list);
            on('click', function(){
                removeClass('is-active', $toggle);
                removeClass('is-open', $list);
            }, $back);
        }
    });
}
"use strict";

//todo check if loading spinner and posterlement is needed
import {onFind} from "@elements/init-modules-in-scope";
import {on, addClass, removeClass, findIn} from "@elements/dom-utils";

export function init () {

    onFind('.js-video', function(videoContainer){
        let video = findIn('.js-video__video', videoContainer),
            playButton = findIn('.js-video__play', videoContainer),
            // videoLoading = findIn('.js-video__loading', videoContainer),
            posterElement = findIn('.js-video__poster', videoContainer);



        if (videoContainer.getAttribute('data-video-url')) {
            const loadVideo = () => {
                // removeAttribute('hidden', videoLoading);
                video.src = videoContainer.getAttribute('data-video-url');

                video.onload = function (){
                    addClass('is-playing', videoContainer);
                    // setAttribute('hidden', true, videoLoading);
                }
            };

            if(playButton){
                on('click', () => loadVideo(), playButton);
            }

            if (posterElement) {
                on('click', () => loadVideo(), posterElement);
            }
        } else {
            const playVideo = () => {
                // removeAttribute('hidden', videoLoading);
                video.play();
            };

            on('playing', function () {
                addClass('is-playing', videoContainer);
                // setAttribute('hidden', true, videoLoading);
            }, video);

            on('pause', function () {
                removeClass('is-playing', videoContainer);
            }, video);

            on('ended', function () {
                removeClass('is-playing', videoContainer);
            }, video);

            if(playButton){
                on('click', () => playVideo(), playButton);
            }
            if (posterElement) {
                on('click', () => playVideo(), posterElement);
            }
        }
    });

}

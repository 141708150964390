import {onFind} from "@elements/init-modules-in-scope";
import {find, addClass, removeClass, on, findIn, findAll} from "@elements/dom-utils";

export function init () {
    onFind('.js-anchor-nav', function (anchorNav) {

        if (anchorNav.getAttribute('data-target')) {
            let target = find(anchorNav.getAttribute('data-target'));

            if (anchorNav.getBoundingClientRect().top < target.getBoundingClientRect().top - target.getBoundingClientRect().height) {
                addClass('is-visible', target);
            }

            let lastScrollTop = 0;
            window.addEventListener('scroll', function() {
                if (lastScrollTop > window.scrollY) {
                    /* scroll up */
                    if (anchorNav.getBoundingClientRect().top > target.getBoundingClientRect().top - target.getBoundingClientRect().height) {
                        removeClass('is-visible', target);
                    }
                } else {
                    /* scroll down */
                    if (anchorNav.getBoundingClientRect().top < target.getBoundingClientRect().top - target.getBoundingClientRect().height) {
                        addClass('is-visible', target);
                    }
                }

                lastScrollTop = window.scrollY;
            }, false);
        }
    });

    on('activate.bs.scrollspy', function () {
        findAll('.js-anchor-nav__list').map((list) => {
            let activeItem = findIn('.active', list);
            if (activeItem) {
                list.scrollLeft += activeItem.getBoundingClientRect().left - list.getBoundingClientRect().left
            }
        });
    }, window);
}
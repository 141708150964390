"use strict";
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn, findAllIn, find} from "@elements/dom-utils";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";
import axios from "axios";

export function init () {
    onFind('.js-localized-video', function($localVideo){
       let url = $localVideo.getAttribute('data-video-url');

       onFind('.js-localized-video-lang', function($lang){
           on('click', function(){
               let language = $lang.getAttribute('data-video-lang');

               axios({
                   method: 'get',
                   headers: {
                       'Content-Type': 'application/json'
                   },
                   url: url,
                   responseType: 'json',
                   params: {
                       language: language
                   }
                   })
                   .then(function (response) {
                       let data = response.data;
                       $localVideo.innerHTML = data.html;
                       initInScope($localVideo);
                   })
                   .catch(function (error) {
                       console.log('error');
                       if (error.response) {
                           console.log(error.response.data);
                           console.log(error.response.status);
                           console.log(error.response.headers);
                       } else if (error.request) {
                           console.log(error.request);
                       } else {
                           console.log('Error', error.message);
                       }
                       console.log(error.config);
                   })
                   .then(function () {
                       // always executed
                   });

           }, $lang);
       });
    });
}